import { AdvertisementsListResultPriceInfoPriceDtoUnitEnum } from 'modules/api/generated';
import { EPriceUnit } from 'modules/api/clients/advertisement/common/enums/parameters/price/EPriceUnit';

// @TODO doriesit enumy, zatial pouzity enum pre listing
export const priceUnitsEnumsRecord: Record<AdvertisementsListResultPriceInfoPriceDtoUnitEnum, EPriceUnit> = {
    [AdvertisementsListResultPriceInfoPriceDtoUnitEnum.Currency]: EPriceUnit.CURRENCY,
    [AdvertisementsListResultPriceInfoPriceDtoUnitEnum.CurrencyPerM2]: EPriceUnit.CURRENCY_PER_M2,
    [AdvertisementsListResultPriceInfoPriceDtoUnitEnum.CurrencyPerMonth]: EPriceUnit.CURRENCY_PER_MONTH,
    [AdvertisementsListResultPriceInfoPriceDtoUnitEnum.CurrencyPerYear]: EPriceUnit.CURRENCY_PER_YEAR,
    [AdvertisementsListResultPriceInfoPriceDtoUnitEnum.CurrencyPerM2PerMonth]: EPriceUnit.CURRENCY_PER_M2_PER_MONTH,
    [AdvertisementsListResultPriceInfoPriceDtoUnitEnum.CurrencyPerM2PerYear]: EPriceUnit.CURRENCY_PER_M2_PER_YEAR,
    [AdvertisementsListResultPriceInfoPriceDtoUnitEnum.CurrencyPerDay]: EPriceUnit.CURRENCY_PER_DAY,
};
