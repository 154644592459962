import { FC } from 'react';
import {
    Wrapper
} from 'modules/theme/components/listing/cards/promoDevProjectCard/components/card/partials/content/partials/wrapper/Wrapper';
import {
    Badges
} from 'modules/theme/components/listing/cards/promoDevProjectCard/components/card/partials/content/partials/badges/Badges';
import {
    Parameters
} from 'modules/theme/components/listing/cards/promoDevProjectCard/components/card/partials/content/partials/parameters/Parameters';
import {
    ECategoriesLabel
} from 'modules/theme/components/listing/cards/promoDevProjectCard/enums/category/ECategoriesLabel';


interface IContent {
    isTop?: boolean;
    price: string;
    location: string;
    unitPrice?: string;
    projectName: string;
    isPremium?: boolean;
    totalAdvertisementsCount: number;
    advertisementsCategories: ECategoriesLabel[];
}

export const Content: FC<IContent> = ({
    isTop,
    price,
    location,
    unitPrice,
    isPremium,
    projectName,
    advertisementsCategories,
    totalAdvertisementsCount
}) => {
    return <Wrapper>
        <Badges isPremium={isPremium} isTop={isTop}/>
        <Parameters
            price={price}
            location={location}
            unitPrice={unitPrice}
            projectName={projectName}
            categories={advertisementsCategories}
            totalAdvertisementsCount={totalAdvertisementsCount}
        />
    </Wrapper>;
};
