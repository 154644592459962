import { FC } from 'react';
import { Parameter } from 'modules/theme/components/listing/cards/common/components/parameters/common/Parameter';
import {
    ECategoriesLabel
} from 'modules/theme/components/listing/cards/promoDevProjectCard/enums/category/ECategoriesLabel';
import {
    categoryIconRecord
} from 'modules/theme/components/listing/cards/promoDevProjectCard/constants/record/categoryIconRecord';
import {
    CategoriesOrder
} from 'modules/theme/components/listing/cards/promoDevProjectCard/constants/categoriesOrder/CategoriesOrder';

interface ICategories {
    categories: ECategoriesLabel[];
}

export const Categories: FC<ICategories> = ({ categories }) => {

    const orderedCategories = categories.sort((a, b) => CategoriesOrder.indexOf(a) - CategoriesOrder.indexOf(b));
    const label = orderedCategories.join(', ');

    return <Parameter variant='label2' value={label} icon={categoryIconRecord[orderedCategories[0]]}/>;
};
