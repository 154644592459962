import { formatDecimal } from 'modules/format/decimal';
import { ILemmatizeWordAccordingToNumber } from 'modules/lemmatize/ILemmatizeWordAccordingToNumber';

export const lemmatizeWordAccordingToNumber = (number: number, words: ILemmatizeWordAccordingToNumber): string => {
    
    const formatedNumber = formatDecimal(number);

    const { eqZero, eqOne, betweenTwoAndFourInclusive, moreThenFour, withoutNumberIfZero, withoutNumber } = words;

    if (number === 0) return (withoutNumberIfZero || withoutNumber) ? eqZero : `${formatedNumber} ${eqZero}`;

    if (number === 1) return withoutNumber ? eqOne : `${formatedNumber} ${eqOne}`;

    if (number >= 2 && number <= 4) return withoutNumber ? betweenTwoAndFourInclusive : `${formatedNumber} ${betweenTwoAndFourInclusive}`;

    return withoutNumber ? moreThenFour : `${formatedNumber} ${moreThenFour}`;
};
