import { FC } from 'react';
import Link from 'components/link/Link';
import { Box, Divider, Theme } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { Button } from 'modules/theme/components/button/Button';

export const Company: FC = () => {
    return (
        <Box>
            <Box my={3}>
                <Divider
                    sx={(theme: Theme) => ({
                        ':before, :after': {
                            borderColor: theme.palette.dividerTransparent,
                        },
                    })}
                >
                    <Text
                        variant='label2'
                        color={(palette) => palette.labelSecondary}
                    >
                        Alebo ste firma?
                    </Text>
                </Divider>
            </Box>

            <Box
                sx={(theme: Theme) => ({
                    '.MuiButton-root': {
                        borderColor: theme.palette.dividerTransparent,
                        '&:hover': {
                            borderColor: theme.palette.labelPrimary.main,
                            backgroundColor:
                                theme.palette.backgroundPrimary.main,
                            color: theme.palette.labelPrimary.main,
                        },
                    },
                })}
            >
                <Link href='https://admin.nehnutelnosti.sk/login'>
                    <Button
                        tertiary
                        fullWidth
                        text={
                            <Text variant='label1' semibold>
                                Vstup pre realitné kancelárie
                            </Text>
                        }
                    />
                </Link>
            </Box>
            {/* <Link href="/registracia-administracny-system">
                <Button
                    textBtn
                    fullWidth
                    text={
                        <Text variant="label2">
                            Registrácia realitnej kancelárie
                        </Text>
                    }
                />
            </Link> */}
        </Box>
    );
};
