import { AdvertisementDetailAdvertisementCategoryDtoMainValueEnum } from 'modules/api/generated';
import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';

export const mainCategoryEnumsFromDtoRecord: Record<AdvertisementDetailAdvertisementCategoryDtoMainValueEnum, EMainCategory> = {
    [AdvertisementDetailAdvertisementCategoryDtoMainValueEnum.Lands]: EMainCategory.LANDS,
    [AdvertisementDetailAdvertisementCategoryDtoMainValueEnum.Houses]: EMainCategory.HOUSES,
    [AdvertisementDetailAdvertisementCategoryDtoMainValueEnum.Spaces]: EMainCategory.SPACES,
    [AdvertisementDetailAdvertisementCategoryDtoMainValueEnum.Objects]: EMainCategory.OBJECTS,
    [AdvertisementDetailAdvertisementCategoryDtoMainValueEnum.Apartments]: EMainCategory.APARTMENTS,
    [AdvertisementDetailAdvertisementCategoryDtoMainValueEnum.RealEstates]: EMainCategory.REAL_ESTATES,
    [AdvertisementDetailAdvertisementCategoryDtoMainValueEnum.CottagesAndCabins]: EMainCategory.COTTAGES_AND_CABINS,

    [AdvertisementDetailAdvertisementCategoryDtoMainValueEnum.SecondaryLands]: EMainCategory.SECONDARY_LANDS,
    [AdvertisementDetailAdvertisementCategoryDtoMainValueEnum.SecondaryHouses]: EMainCategory.SECONDARY_HOUSES,
    [AdvertisementDetailAdvertisementCategoryDtoMainValueEnum.SecondarySpacesAndObjects]: EMainCategory.SECONDARY_SPACES_AND_OBJECTS,
    [AdvertisementDetailAdvertisementCategoryDtoMainValueEnum.SecondaryRecreationalProperties]: EMainCategory.SECONDARY_RECREATIONAL_PROPERTIES,
};