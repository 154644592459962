import { FC } from 'react';
import { Box, Theme } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { Svg } from 'modules/theme/components/svg/Svg';
import { ELoginPosition } from 'modules/gtmEvents/enums/login/ELoginPosition';
import { useSocialLogin } from 'modules/page/app/useSocialLogin';
import { ELoginMethod } from 'modules/gtmEvents/enums/login/ELoginMethod';

interface ISocialLoginButton {
    name: string,
    providerUrl: string,
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    method: ELoginMethod,
    position: ELoginPosition,
}

export const SocialLoginButton: FC<ISocialLoginButton> = ({ name, providerUrl, icon, method, position }) => {

    const { onClick } = useSocialLogin(method, position, providerUrl);

    return (
        <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            gap={.5}
            onClick={() => {
                onClick();
            }}
            sx={{ cursor: 'pointer' }}
        >
            <Box
                borderRadius={1}
                display='flex'
                justifyContent='center'
                alignItems='center'
                width='100%'
                height={56}
                sx={{ border: (theme: Theme) => `1px solid ${theme.palette.dividerTransparent}` }}
            >
                <Svg icon component={icon} width={24} height={24} />
            </Box>
            <Text variant='label2' semibold>
                {name}
            </Text>
        </Box>

    );
};
