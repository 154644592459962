import AzetIcon from 'modules/theme/components/icons/social/Azet.svg';
import GoogleIcon from 'modules/theme/components/icons/social/Google.svg';
import FacebookIcon from 'modules/theme/components/icons/social/Facebook.svg';

import { ESocialLogin } from 'components/loginModal/enums/ESocialLogin';
import { ISocialLogin } from 'components/loginModal/intefaces/ISocialLogin';
import { AppConfigService } from 'modules/appConfig/AppConfigService';
import { ELoginMethod } from 'modules/gtmEvents/enums/login/ELoginMethod';

export const SocialLogin: Record<ESocialLogin, ISocialLogin> = {
    [ESocialLogin.AZET]: {
        name: 'Azet',
        icon: AzetIcon,
        providerUrl: 'azet',
        method: ELoginMethod.AZET,
    },
    [ESocialLogin.FACEBOOK]: {
        name: 'Facebook',
        icon: FacebookIcon,
        providerUrl: `facebook-${AppConfigService.getPlatformProjectName()}`,
        method: ELoginMethod.FACEBOOK,
    },
    [ESocialLogin.GOOGLE]: {
        name: 'Google',
        icon: GoogleIcon,
        providerUrl: `google-${AppConfigService.getPlatformProjectName()}`,
        method: ELoginMethod.GOOGLE,
    },
    [ESocialLogin.UC]: {
        name: 'UC',
        icon: GoogleIcon,
        providerUrl: `${AppConfigService.getPlatformProjectName()}`,
        method: ELoginMethod.UC,
    },
};
