import React, { FC } from 'react';
import CloseIcon from 'modules/theme/components/icons/basic/Close.svg';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Box } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { ButtonsBox } from 'components/loginModal/partials/content/partials/buttonsBox/ButtonsBox';
import brandLogo from 'components/loginModal/img/svg/brandLogo.svg';
import { Company } from 'components/layout/header/components/navbar/common/links/user/loggedOut/partials/content/partials/company/Company';

interface IProps {
    ctaText?: string;
    onClose?: () => void;
    showCompany?: boolean;
}

export const Content: FC<IProps> = ({ ctaText, onClose, showCompany = true }) => {
    return (
        <>
            <Box
                onClick={onClose}
                width='fit-content'
                height='fit-content'
                sx={{
                    cursor: 'pointer',
                }}
            >
                <Svg icon component={CloseIcon} width={24} />
            </Box>
            <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                gap={1}
                marginTop={-1}
            >
                <Svg component={brandLogo} width={28} height={32} />
                <Text variant='h4' semibold>
                    Prihláste sa
                </Text>
                <Text
                    center
                    variant='body3'
                    color={(palette) => palette.labelSecondary}
                >
                    {ctaText || 'Pre pokračovanie sa, prosím, prihláste.'}
                </Text>
            </Box>
            <Box marginTop={2}>
                <ButtonsBox />
                {showCompany && <Company />}
            </Box>
        </>
    );
};
