import Auth from '@uc-platform/auth';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { GA4_COOKIE, GA4_COOKIE_EVENT_DISPATCH } from 'components/loginModal/constants/Ga4LoginCookie';
import { encodeJson } from 'modules/url/encodeJson';
import { ELoginPosition } from 'modules/gtmEvents/enums/login/ELoginPosition';
import { ELoginMethod } from 'modules/gtmEvents/enums/login/ELoginMethod';

interface IReturn {
    onClick: () => void;
}

export const useSocialLogin = (
    method: ELoginMethod,
    position: ELoginPosition,
    providerUrl: string,
): IReturn => {

    const onClick = () => {
        window['ucAuthLoginDialogActivated'] = true;

        if (getCookie(GA4_COOKIE.name, { path: GA4_COOKIE.path })) {
            deleteCookie(GA4_COOKIE.name, { path: GA4_COOKIE.path });
        }

        if (getCookie(GA4_COOKIE_EVENT_DISPATCH.name, { path: GA4_COOKIE_EVENT_DISPATCH.path })) {
            deleteCookie(GA4_COOKIE_EVENT_DISPATCH.name, { path: GA4_COOKIE_EVENT_DISPATCH.path });
        }

        const expires = new Date();
        expires.setTime(expires.getTime() + (60 * 10000));
        setCookie(GA4_COOKIE.name, encodeJson({
            method,
            position,
        }), { path: GA4_COOKIE.path, expires: expires });

        setCookie(GA4_COOKIE_EVENT_DISPATCH.name, encodeJson({
            method,
            position,
        }), { path: GA4_COOKIE_EVENT_DISPATCH.path, expires: expires });

        Auth.showLoginDialog(providerUrl);
    };

    return {
        onClick,
    };
};
