import { FC } from 'react';
import icon from 'modules/theme/components/icons/basic/MappinOutline.svg';
import { Parameter } from 'modules/theme/components/listing/cards/common/components/parameters/common/Parameter';

interface ILocation {
    location: string;
}

export const Location: FC<ILocation> = ({ location }) => {
    return <Parameter variant='label2' value={location} icon={icon}/>;
};
