import { FC } from 'react';
import { Stack } from '@mui/material';
import {
    PackageBadges
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/photoPart/preview/partials/badges/partials/top/partials/PackageBadges';
import {
    Label
} from 'modules/theme/components/listing/cards/promoDevProjectCard/components/card/partials/content/partials/badges/partials/label/Label';

interface IBadges {
    isTop?: boolean;
    isPremium?: boolean;
}

export const Badges: FC<IBadges> = ({ isTop, isPremium }) => {
    return <Stack direction='row' justifyContent='space-between' alignItems='start'>
        <PackageBadges isTop={isTop} isPremium={isPremium}/>
        <Label/>
    </Stack>;

};
