import { AdvertisementDetailAdvertisementCategoryDtoSubValueEnum } from 'modules/api/generated';
import { ESubCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';

export const subCategoryEnumsFromDtoRecord: Record<AdvertisementDetailAdvertisementCategoryDtoSubValueEnum, ESubCategory> = {
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.StudioApartment]: ESubCategory.STUDIO_APARTMENT,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.DoubleStudioApartment]: ESubCategory.DOUBLE_STUDIO_APARTMENT,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.OneRoomApartment]: ESubCategory.ONE_ROOM_APARTMENT,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.TwoRoomApartment]: ESubCategory.TWO_ROOM_APARTMENT,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.ThreeRoomApartment]: ESubCategory.THREE_ROOM_APARTMENT,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.FourRoomApartment]: ESubCategory.FOUR_ROOM_APARTMENT,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.FivePlusRoomApartment]: ESubCategory.FIVE_PLUS_ROOM_APARTMENT,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.HolidayApartment]: ESubCategory.HOLIDAY_APARTMENT,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.Loft]: ESubCategory.LOFT,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.Maisonette]: ESubCategory.MAISONETTE,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.OtherTypeOfApartment]: ESubCategory.OTHER_TYPE_OF_APARTMENT,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.FamilyHouse]: ESubCategory.FAMILY_HOUSE,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.CountryHouse]: ESubCategory.COUNTRY_HOUSE,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.FarmSettlement]: ESubCategory.FARM_SETTLEMENT,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.MobileHouse]: ESubCategory.MOBILE_HOUSE,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.Houseboat]: ESubCategory.HOUSEBOAT,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.OtherObjectForHousing]: ESubCategory.OTHER_OBJECT_FOR_HOUSING,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.CabinAndLogCabin]: ESubCategory.CABIN_AND_LOG_CABIN,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.CottageAndRecreationHouse]: ESubCategory.COTTAGE_AND_RECREATION_HOUSE,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.GardenHut]: ESubCategory.GARDEN_HUT,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.OtherRecreationalObject]: ESubCategory.OTHER_RECREATIONAL_OBJECT,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.LandForFamilyHouse]: ESubCategory.LAND_FOR_FAMILY_HOUSE,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.RecreationalLand]: ESubCategory.RECREATIONAL_LAND,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.LandForHousingConstruction]: ESubCategory.LAND_FOR_HOUSING_CONSTRUCTION,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.LandForCivicAmenities]: ESubCategory.LAND_FOR_CIVIC_AMENITIES,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.CommercialZone]: ESubCategory.COMMERCIAL_ZONE,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.IndustrialZone]: ESubCategory.INDUSTRIAL_ZONE,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.MixedZone]: ESubCategory.MIXED_ZONE,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.Garden]: ESubCategory.GARDEN,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.Orchard]: ESubCategory.ORCHARD,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.VineyardAndHopGarden]: ESubCategory.VINEYARD_AND_HOP_GARDEN,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.MeadowAndPasture]: ESubCategory.MEADOW_AND_PASTURE,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.ArableLand]: ESubCategory.ARABLE_LAND,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.ForestLand]: ESubCategory.FOREST_LAND,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.PondAndLake]: ESubCategory.POND_AND_LAKE,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.LandForAdvertising]: ESubCategory.LAND_FOR_ADVERTISING,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.OtherTypeOfLand]: ESubCategory.OTHER_TYPE_OF_LAND,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.Offices]: ESubCategory.OFFICES,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.BusinessSpaces]: ESubCategory.BUSINESS_SPACES,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.RestaurantSpaces]: ESubCategory.RESTAURANT_SPACES,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.StorageAreas]: ESubCategory.STORAGE_AREAS,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SpaceForProduction]: ESubCategory.SPACE_FOR_PRODUCTION,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.RepairArea]: ESubCategory.REPAIR_AREA,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SportsFacilities]: ESubCategory.SPORTS_FACILITIES,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SpaceForAdvertisement]: ESubCategory.SPACE_FOR_ADVERTISEMENT,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.OtherTypeOfSpace]: ESubCategory.OTHER_TYPE_OF_SPACE,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.ApartmentHouse]: ESubCategory.APARTMENT_HOUSE,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.RentalHouse]: ESubCategory.RENTAL_HOUSE,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.OfficeBuilding]: ESubCategory.OFFICE_BUILDING,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.CommercialObject]: ESubCategory.COMMERCIAL_OBJECT,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.Restaurant]: ESubCategory.RESTAURANT,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.PolyfunctionalBuilding]: ESubCategory.POLYFUNCTIONAL_BUILDING,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.Warehouse]: ESubCategory.WAREHOUSE,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.ManufacturingFacility]: ESubCategory.MANUFACTURING_FACILITY,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.RepairFacility]: ESubCategory.REPAIR_FACILITY,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.ObjectForBreedingAnimals]: ESubCategory.OBJECT_FOR_BREEDING_ANIMALS,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.HotelAndPension]: ESubCategory.HOTEL_AND_PENSION,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.Spa]: ESubCategory.SPA,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.HistoricalObject]: ESubCategory.HISTORICAL_OBJECT,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.CommercialFacility]: ESubCategory.COMMERCIAL_FACILITY,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SportObject]: ESubCategory.SPORT_OBJECT,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SmallPowerStation]: ESubCategory.SMALL_POWER_STATION,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.GasStation]: ESubCategory.GAS_STATION,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.MobileCellsAndStands]: ESubCategory.MOBILE_CELLS_AND_STANDS,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.OtherTypeOfObject]: ESubCategory.OTHER_TYPE_OF_OBJECT,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.DetachedGarage]: ESubCategory.DETACHED_GARAGE,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.ConsolidatedGround]: ESubCategory.CONSOLIDATED_GROUND,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.AgriculturalObject]: ESubCategory.AGRICULTURAL_OBJECT,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.OtherLandToBuilding]: ESubCategory.OTHER_LAND_TO_BUILDING,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.OtherAgriculturalLand]: ESubCategory.OTHER_AGRICULTURAL_LAND,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryStudioApartment]: ESubCategory.SECONDARY_STUDIO_APARTMENT,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryThreeRoomApartment]: ESubCategory.SECONDARY_THREE_ROOM_APARTMENT,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryLandForFamilyHouses]: ESubCategory.SECONDARY_LAND_FOR_FAMILY_HOUSES,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryRecreationalLand]: ESubCategory.SECONDARY_RECREATIONAL_LAND,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryCommercialPlots]: ESubCategory.SECONDARY_COMMERCIAL_PLOTS,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryGarden]: ESubCategory.SECONDARY_GARDEN,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryOrchardsVineyardsHopFields]: ESubCategory.SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryAgriculturalAndForestLand]: ESubCategory.SECONDARY_AGRICULTURAL_AND_FOREST_LAND,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryGarageAndParking]: ESubCategory.SECONDARY_GARAGE_AND_PARKING,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryOfficesAndAdministration]: ESubCategory.SECONDARY_OFFICES_AND_ADMINISTRATION,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryTheShop]: ESubCategory.SECONDARY_THE_SHOP,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryARestaurant]: ESubCategory.SECONDARY_A_RESTAURANT,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryWarehouses]: ESubCategory.SECONDARY_WAREHOUSES,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryProduction]: ESubCategory.SECONDARY_PRODUCTION,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryAnotherSpaceAndObject]: ESubCategory.SECONDARY_ANOTHER_SPACE_AND_OBJECT,
    [AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryHotelGuesthouse]: ESubCategory.SECONDARY_HOTEL_GUESTHOUSE,
};
