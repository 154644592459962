import { useLocalStorageItem } from 'modules/localStorage/hooks/useLocalStorageitem';

interface IReturn {
    handleAfterClose: () => void;
}

export const useOnLoginModalClose = (): IReturn => {
    const {
        remove: removeStorageAdvIdFavorite,
    } = useLocalStorageItem('add-to-favorites');
    const {
        remove: removeStorageAdvIdComparison,
    } = useLocalStorageItem('add-to-comparison');

    const {
        remove: removeSearchAgentShowLSItem,
    } = useLocalStorageItem('isSearchAgentClicked');

    const handleAfterClose = (): void => {
        removeStorageAdvIdFavorite();
        removeStorageAdvIdComparison();
        removeSearchAgentShowLSItem();
    };

    return {
        handleAfterClose,
    };
};