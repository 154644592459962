import { ReactNode, useState } from 'react';
import useDialog from 'modules/dialog/useDialog';
import { Theme, useMediaQuery } from '@mui/material';
import LoginModal from 'components/loginModal/LoginModal';
import useSession from 'modules/stores/session/useSession';
import { useOnLoginModalClose } from 'components/loginModal/hooks/useOnLoginModalClose';
import { Drawer } from 'components/layout/header/components/navbar/common/links/user/loggedOut/mobile/drawer/Drawer';
import { Content } from 'components/layout/header/components/navbar/common/links/user/loggedOut/partials/content/Content';

interface IReturn {
    isLoggedIn: boolean;
    loginModal: ReactNode;
    showLoginDialog: () => void;
    hideLoginDialog: () => void;
    setLoginCtaText: (ctaText: string) => void;
}

export const useLoginModal = (): IReturn => {
    const { session } = useSession();
    const isLoggedIn = !!session;
    const [loginCtaText, setLoginCtaText] = useState<string>('');

    const { handleAfterClose } = useOnLoginModalClose();

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const handleClose = () => {
        hideLoginDialog();
        handleAfterClose();
        setIsDrawerOpen(false);
    };

    const handleShowDrawer = () => {
        setIsDrawerOpen(true);
    };

    const {
        component: loginModal,
        show: showLoginDialog,
        hide: hideLoginDialog,
    } = useDialog({
        content: (
            <LoginModal ctaText={loginCtaText} onClose={() => handleClose()} />
        ),
        paperProps: {
            style: {
                width: 400,
                minHeight: 276,
                borderRadius: 16,
            },
        },
        dialogContentProps: {
            sx: {
                padding: 4,
            },
        },
        wrapContentIntoBox: false,
    });

    const isDesktop = useMediaQuery((theme: Theme) =>
        theme.breakpoints.up('md')
    );

    const handleShowLoginDialog = () => {
        if (isDesktop) {
            showLoginDialog();
        } else {
            handleShowDrawer();
        }
    };

    return {
        isLoggedIn,
        loginModal: (
            <>
                {isDesktop && loginModal}

                {!isDesktop && (
                    <Drawer isOpen={isDrawerOpen} onClose={handleClose}>
                        <Content ctaText={loginCtaText} onClose={handleClose} showCompany={false} />
                    </Drawer>
                )}
            </>
        ),
        showLoginDialog: handleShowLoginDialog,
        hideLoginDialog,
        setLoginCtaText,
    };
};
