import { FunctionComponent, SVGProps } from 'react';

import ApartmentIcon from 'modules/theme/components/icons/category/ApartmentOutline.svg';
import HouseOutlineIcon from 'modules/theme/components/icons/category/HouseOutline.svg';
import CabinOutlineIcon from 'modules/theme/components/icons/category/CabinOutline.svg';
import SpaceOutlineIcon from 'modules/theme/components/icons/category/SpaceOutline.svg';
import ObjectOutlineIcon from 'modules/theme/components/icons/category/ObjectOutline.svg';
import LandOutlineIcon from 'modules/theme/components/icons/category/LandOutline.svg';
import {
    ECategoriesLabel
} from 'modules/theme/components/listing/cards/promoDevProjectCard/enums/category/ECategoriesLabel';

export const categoryIconRecord: Record<ECategoriesLabel, FunctionComponent<SVGProps<SVGSVGElement>>> = {
    [ECategoriesLabel.APARTMENTS]: ApartmentIcon,
    [ECategoriesLabel.HOUSES]: HouseOutlineIcon,
    [ECategoriesLabel.SPACES]: SpaceOutlineIcon,
    [ECategoriesLabel.LANDS]: LandOutlineIcon,
    [ECategoriesLabel.OBJECTS]: ObjectOutlineIcon,
    [ECategoriesLabel.COTTAGES_AND_CABINS]: CabinOutlineIcon,
    [ECategoriesLabel.SECONDARY_RECREATIONAL_PROPERTIES]: CabinOutlineIcon,
    [ECategoriesLabel.SECONDARY_SPACES_AND_OBJECTS]: SpaceOutlineIcon,
};
