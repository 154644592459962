import { FC, ReactNode } from 'react';
import { Box, Drawer as MuiDrawer } from '@mui/material';

interface IDrawer {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
}

export const Drawer: FC<IDrawer> = ({ isOpen, onClose, children }) => {
    return (
        <MuiDrawer open={isOpen} anchor='bottom' onClose={onClose}>
            <Box
                borderRadius='20px'
                bgcolor={(theme) => theme.palette.backgroundPrimary.main}
                p={4}
                pt={3}
                sx={{
                    overflow: 'hidden',
                    backdropFilter: 'blur(16px)',
                    boxShadow: '0px 8px 24px 0px rgba(0, 0, 0, 0.16)',
                }}
            >
                {children}
            </Box>
        </MuiDrawer>
    );
};
